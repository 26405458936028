import { FC, useCallback } from 'react';
import 'primeflex/primeflex.css';
import './GridOperatorSelection.scss';
import 'primeicons/primeicons.css';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { useRecoilValue, useRecoilState } from 'recoil';
import { gridOperatorsState, selectedGridOperatorIdState } from './../../atoms/gridOperators';
import { GridOperator } from '../../middleware/api';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { useResetState } from '../../hooks/useResetState';

const GridOperatorSelection: FC = () => {
	const { t } = useTranslation();
	const gridOperators = useRecoilValue<GridOperator[]>( gridOperatorsState );
	const navigate = useNavigate();
	const resetState = useResetState();
	const [ , setSelectedGridOperatorIdState ] = useRecoilState( selectedGridOperatorIdState );
	const selectGrid = ( grid: any ) => {
		setSelectedGridOperatorIdState( grid );
		navigate( '/damage' );
	}

	const onLogOutHandler = useCallback( () => {
		localStorage.removeItem( 'authentication' );
		localStorage.removeItem( 'gridOperators' );
		resetState();
	}, [ resetState ] );

	return (
		<div className="flex flex-column align-items-center justify-content-center p-input-filled">
			<div className="top-controls header flex align-items-center">
				<div className="top-control-left">
				</div>
				<div className="top-control-center">
					<h2>{ t( 'Grid operators' ) }</h2>
				</div>
				<div className="top-control-right">
					<Button
						className="p-button-text p-button-danger"
						icon="pi pi-sign-out"
						label={ t( 'Logout' ) }
						onClick={ onLogOutHandler } />
				</div>
			</div>

			<div className="mt-4 p-4 flex flex-row flex-wrap align-items-center grid-operators-container">
				{ gridOperators.map( ( option: any ) => (
					<div className="grid-operator" key={ option.id } onClick={ () => selectGrid( option.id ) }>
						<Card className="flex flex-row flex-wrap align-items-center justify-content-center logo-card">
							<div className="image-container flex flex-column flex-wrap align-items-center justify-content-center">
								<div className="image-top flex align-items-center">
									<img className="logo" src={ option[ 'logo_url' ] } alt={ option.name } />
								</div>
								<div className="grid-name">{ option.name }</div>
							</div>
						</Card>
					</div>
				) ) }
			</div>
		</div>
	)
};

export default GridOperatorSelection;
