import { useRecoilState } from 'recoil';
import { tokenState } from '../atoms/auth';
import { damageFormState } from '../atoms/damageForm';
import { gridOperatorsState, selectedGridOperatorIdState } from '../atoms/gridOperators';
import { GridOperator } from '../middleware/api';
import { DamageFormFields } from '../screens/DamageForm/DamageFormTypes';

export const useResetState = () => {
	const [ , setGridOperators ] = useRecoilState<GridOperator[]>( gridOperatorsState );
	const [ , setSelectedGridOperatorIdState ] = useRecoilState<number>( selectedGridOperatorIdState );
	const [ , setToken ] = useRecoilState<string>( tokenState );
	const [ , setDamageForm ] = useRecoilState<DamageFormFields>( damageFormState );

	return () => {
		setGridOperators( [] );
		setSelectedGridOperatorIdState( 0 );
		setToken( '' );
		setDamageForm( {
			cause: '',
			cause_subset: '',
			equipment: '',
			grid_sort: '',
			origin: '',
			type_of_work: '',
			diameter: '',
			grid_type: '',
			material: '',
			work_type: '',
			street: '',
			city: '',
			house_number: '',
			postcode: '',
			circumstances: '',
			party_remarks: '',
			agree_remarks: '0',
			commissioner: '',
			main_contractor: '',
			causative_party: '',
			contact_name: '',
			contact_email: '',
			contact_phone: '',
			previously_caused: '',
			previously_caused_date: '',
			notified_grid_operator: '',
			klic_notification: '',
			klic_at_work: '',
			klic_damaged_item: '',
			evides_email: '',
			item_localised: '',
			localisations_recorded: '',
			work_instructions: '',
			any_deviations: '',
			deviation_value: '',
			new_field_options: [],
			lat: '',
			lng: '',
			action_plan: ''
		} )
	};
};
