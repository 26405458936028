export interface Config {
	apiUrl: string;
	geocodingApiKey: string;
	sentryDsn: string;
}

const defaultConfig = {
	apiUrl: `https://inxs-web.test.cuba.up2technology.com/api/v1/`,
	geocodingApiKey: `HnV64F7tCxZhx2FE`,
	sentryDsn: 'https://7945fabcba254039bfb08380848fb095@o398716.ingest.sentry.io/5823076',
}


const config: Config = ( window && window.config ) ? window.config : defaultConfig;

export default config;