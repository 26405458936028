import React from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import { LatLng, LatLngTuple } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import LocationMarker from './LocationMarker';
let temp = localStorage.getItem( 'damageForm' ) ? JSON.parse( localStorage.getItem( 'damageForm' ) || '' ) : {};
const defaultLatLng: LatLngTuple = temp.damageFormState && temp.damageFormState.lat !== '' ? [ temp.damageFormState.lat, temp.damageFormState.lng ] : [ 51.9244201, 4.4777325 ];
const zoom: number = 18;
export interface MapProps {
	onPositionChange: ( newPosition: LatLng ) => void;
	mapRef: any;
}

const LeafletMap: React.FC<MapProps> = ( { onPositionChange, mapRef } ) => {
	return (
		<MapContainer center={ defaultLatLng }
			zoom={ zoom }
			ref={ mapRef }
			preferCanvas={ true }>
			<TileLayer
				attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community"
				url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
				maxNativeZoom={ 19 }
				minZoom={ 0 }
				maxZoom={ 21 }
			/>
			<LocationMarker onPositionChange={ onPositionChange } />
		</MapContainer>
	)
}

export default LeafletMap;
