import React from 'react';
import './App.scss';
import AddToHomeScreenPrompt from './../AddToHomeScreenPrompt';
import UpdateAppMessage from './../UpdateAppMessage';
import { useServiceWorker } from './../../hooks/useServiceWorker';
import {
	BrowserRouter as Router,
	Routes,
	Route,
} from 'react-router-dom';
import Login from '../../screens/Login/Login';
import GridOperatorSelection from '../../screens/GridOperatorSelection/GridOperatorSelection';
import DamageForm from '../../screens/DamageForm/DamageForm';
import GuardedRoute from '../GuardedRoute/GuardedRoute';
const App: React.FC = () => {
	const { isUpdateAvailable, updateAssets } = useServiceWorker();

	return (
		<Router>
			<Routes>
				<Route
					path="/damage"
					element={
						<GuardedRoute redirectTo="/">
							<DamageForm />
						</GuardedRoute>
					}
				/>
				<Route
					path="/grid-operators"
					element={
						<GuardedRoute redirectTo="/">
							<GridOperatorSelection />
						</GuardedRoute>
					}
				/>
				<Route
					path="/"
					element={
						<>
							<AddToHomeScreenPrompt />
							<Login />
							{ isUpdateAvailable && <UpdateAppMessage onUpdate={ updateAssets } /> }

						</>
					}
				/>
			</Routes>
		</Router>
	)
};

export default App;
