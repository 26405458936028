import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import config from "../config-loader";

export const initializeSentry = () => {
	Sentry.init( {
		dsn: config.sentryDsn,
		integrations: [ new Integrations.BrowserTracing() ],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
		beforeSend ( event, hint ) {
			if ( hint.originalException && shouldIgnoreError( hint.originalException as Error ) ) {
				return null; // Drop the event
			}
			if ( event.type === "transaction" && shouldIgnoreTransaction( event ) ) {
				return null;
			}

			return event;
		}
	} );
}

// exclude reporting known errors: browser Camera access denials
const shouldIgnoreError = ( exception: Error ): boolean => {
	return exception.message.includes( "denied" ) ||
		exception.message.includes( "not allowed" );
}

// filter out hight volume transaction/navigation events
const shouldIgnoreTransaction = ( event: Sentry.Event ) => {
	if ( event.transaction === "/damage" || event.transaction?.startsWith( "/grid-operators" ) ) {
		return true;
	}
	return false;
};