import { atom } from 'recoil';
import { GridOperator } from '../middleware/api';

import { recoilPersist } from 'recoil-persist';

const { persistAtom: persistGridOperatorsAtom } = recoilPersist( { key: 'gridOperators' } );
const { persistAtom: persistSelectedGridOperatorsAtom } = recoilPersist( { key: 'selectedGridOperator' } );

export const gridOperatorsState = atom<GridOperator[]>( {
	key: 'gridOperatorsState', // unique ID (with respect to other atoms/selectors)
	default: [], // default value (aka initial value),
	effects_UNSTABLE: [ persistGridOperatorsAtom ]
} );

export const selectedGridOperatorIdState = atom( {
	key: 'selectedGridOperatorIdState',
	default: 0,
	effects_UNSTABLE: [ persistSelectedGridOperatorsAtom ]
} );
