import { LatLng, LatLngTuple } from 'leaflet';
import React, { useCallback } from 'react'
import LeafletMap from '../../components/map/LeafletMap';
import api from '../../middleware/api';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from 'react-i18next';
import { Address } from './DamageFormTypes';
export interface LocationFieldsProps {
	city: string;
	postcode: string;
	street: string;
	houseNumber: string;
	onLocationChange: ( location: Address ) => void;
	mapRef: any;

}

const LocationFields: React.FC<LocationFieldsProps> = ( {
	city,
	postcode,
	street,
	houseNumber,
	onLocationChange,
	mapRef
} ) => {
	const { t } = useTranslation();
	const onPositionChange = useCallback( ( latLng: LatLng | LatLngTuple ) => {
		const realLatLing = Array.isArray( latLng ) ?
			new LatLng( latLng[ 0 ], latLng[ 1 ] )
			:
			new LatLng( latLng.lat, latLng.lng );

		api.getAddress( { latLng: realLatLing } )
			.then( ( res ) => {
				onLocationChange( {
					'city': res.settlement,
					'postcode': res.postcodes[ 0 ],
					'street': res.street,
					'house_number': res.streetnumber,
					'lat': realLatLing.lat,
					'lng': realLatLing.lng
				} )
			} )
			.catch( err => {
				console.log( { locationError: err } )
			} )
	}, [ onLocationChange ] );

	const changeHandler = ( e: any ) => {
		let { name, value } = e.target;
		onLocationChange( { [ name ]: value } );
	}

	return (
		<>
			<div className="map-container">
				<LeafletMap onPositionChange={ onPositionChange } mapRef={ mapRef } />
			</div>
			<div className="address-container flex flex-row justify-content-between">
				<div className="flex flex-column field mb-0">
					<label className="mb-2 flex-item" htmlFor="street">{ t( 'Street' ) }</label>
					<div className="flex-item">
						<InputText
							id="street"
							type="text"
							name="street"
							value={ street }
							onChange={ changeHandler }
							className={ street ? '' : 'empty' }
						/>
					</div>
				</div>
				<div className="flex flex-column field mb-0">
					<label className="mb-2 flex-item" htmlFor="streetNumber">{ t( 'No.' ) }</label>
					<div className="flex-item">
						<InputText
							id="streetNumber"
							type="text"
							name="house_number"
							value={ houseNumber }
							onChange={ changeHandler }
							className={ houseNumber ? '' : 'empty' }
						/>
					</div>
				</div>
				<div className="flex flex-column field mb-0">
					<label className="mb-2 flex-item" htmlFor="postcode">{ t( 'Postcode' ) }</label>
					<div className="flex-item">
						<InputText
							id="postcode"
							type="text"
							name="postcode"
							value={ postcode }
							onChange={ changeHandler }
							className={ postcode ? '' : 'empty' }
						/>
					</div>
				</div>
				<div className="flex flex-column field mb-0">
					<label className="mb-2 flex-item" htmlFor="city">{ t( 'City' ) }</label>
					<div className="flex-item">
						<InputText
							id="city"
							type="text"
							name="city"
							value={ city }
							onChange={ changeHandler }
							className={ city ? '' : 'empty' }
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default LocationFields;