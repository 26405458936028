import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageCategoryEnum } from '../../atoms/imageInputs';
import './DamageImagesCapture.scss';
import ImagePicker from './ImagePicker';

interface DamageImagesCaptureProps { }

const DamageImagesCapture: React.FC<DamageImagesCaptureProps> = () => {
	const [ selectedCategory, setSelectedCategory ] = useState<ImageCategoryEnum>( ImageCategoryEnum.Surrounding );
	const { t } = useTranslation();

	const selectSurrounding = useCallback( () => {
		setSelectedCategory( ImageCategoryEnum.Surrounding );
	}, [ setSelectedCategory ] );

	const selectCloseUp = useCallback( () => {
		setSelectedCategory( ImageCategoryEnum.CloseUps );
	}, [ setSelectedCategory ] );

	const selectEquipment = useCallback( () => {
		setSelectedCategory( ImageCategoryEnum.Equipment );
	}, [ setSelectedCategory ] );

	const selectOther = useCallback( () => {
		setSelectedCategory( ImageCategoryEnum.Other );
	}, [ setSelectedCategory ] );

	return (
		<div className="categorized-image-picker">
			<div className="image-picker-categories">
				<button
					className={ `p-button-link category-tab ${ selectedCategory === ImageCategoryEnum.Surrounding ? 'active' : '' }` }
					type="button"
					onClick={ selectSurrounding }>
					{ t( 'Surroundings' ) }
				</button>
				<button
					className={ `p-button-link category-tab ${ selectedCategory === ImageCategoryEnum.CloseUps ? 'active' : '' }` }
					type="button"
					onClick={ selectCloseUp }>
					{ t( 'CloseUps' ) }
				</button>
				<button
					className={ `p-button-link category-tab ${ selectedCategory === ImageCategoryEnum.Equipment ? 'active' : '' }` }
					type="button"
					onClick={ selectEquipment }>
					{ t( 'Equipment' ) }
				</button>
				<button
					className={ `p-button-link category-tab ${ selectedCategory === ImageCategoryEnum.Other ? 'active' : '' }` }
					type="button"
					onClick={ selectOther }>
					{ t( 'Other' ) }
				</button>
			</div>
			<ImagePicker category={ selectedCategory } />
		</div>
	);
};

export default DamageImagesCapture;
