import { FC, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { useForm } from "react-hook-form";
import { Button } from 'primereact/button';
import 'primeflex/primeflex.css';
import './Login.scss';
import { useTranslation } from 'react-i18next';
import api from './../../middleware/api';
import { useRecoilState, useRecoilValue } from 'recoil';
import { gridOperatorsState, selectedGridOperatorIdState } from './../../atoms/gridOperators';
import { tokenState } from './../../atoms/auth';
import { userState } from './../../atoms/username';
import { ReactComponent as EnglishFlagIcon } from '../../images/english.svg';
import { ReactComponent as DutchFlagIcon } from '../../images/dutch.svg';
import logo from '../../images/inxs-logo.svg';

type loginForm = {
	username: string;
	password: string;
};

const Login: FC = () => {
	const { t } = useTranslation();
	const { register, handleSubmit, formState: { errors } } = useForm<loginForm>();
	const [ , setGridOperators ] = useRecoilState( gridOperatorsState );
	const [ , setSelectedGridOperatorIdState ] = useRecoilState( selectedGridOperatorIdState );
	const [ , setToken ] = useRecoilState( tokenState );
	const [ , setUserState ] = useRecoilState( userState );
	const alreadyLogged = useRecoilValue<string>( tokenState ) !== '' ? true : false;
	const selectedGrid = useRecoilValue<number>( selectedGridOperatorIdState );
	const [ isLoggedIn, setIsLoggedIn ] = useState( alreadyLogged );
	const [ error, setError ] = useState( '' );
	let temp = localStorage.getItem( 'username' ) ? JSON.parse( localStorage.getItem( 'username' ) || '' ) : {};
	const [ username, setUsername ] = useState( temp.userNameTokenState || '' );
	const { i18n } = useTranslation();
	const changeLanguage = ( lng: string ) => {
		i18n.changeLanguage( lng );
	}

	const onSubmit = ( data: loginForm ) => {
		setUserState( data.username );
		api.login( {
			email: data.username,
			password: data.password
		} ).then( response => {
			setToken( response.api_token );
			setGridOperators( response.grid_operators );
			//use the response to redirect to the grid operators page or the form one (if only 1 operator)
			if ( response.grid_operators.length === 1 ) {
				setSelectedGridOperatorIdState( response.grid_operators[ 0 ].id )
			}

			setIsLoggedIn( true );
		} ).catch( e => {
			setError( e );
		} );
	}

	if ( isLoggedIn && selectedGrid !== 0 ) {
		return <Navigate to='/damage' />
	} else if ( isLoggedIn ) {
		return <Navigate to='/grid-operators' />
	}

	return (
		<div>
			<div className="language-bar">
				<EnglishFlagIcon onClick={ () => changeLanguage( 'en' ) } width={ 39 } className={ `mr-2 language-icon ${ i18n.language === 'en' ? "active" : "" }` } />
				<DutchFlagIcon onClick={ () => changeLanguage( 'nl' ) } width={ 39 } className={ `mr-2 language-icon ${ i18n.language === 'nl' ? "active" : "" }` } />
			</div>
			<div className="mb-16 flex flex-column align-items-center justify-content-center main-container input-filled">
				<div className="flex flex-column align-items-center form-container">
					<img src={ logo } alt="img-data" className="login-image" />
					<form className="mb-4 mt-0 flex flex-column align-items-center" onSubmit={ handleSubmit( onSubmit ) }>
						{ error ? (
							<div className="mb-2 p-ta error-message">
								{ error }
							</div>
						) : null }
						<div className="flex flex-column field mb-0">
							<InputText
								id="username"
								type="text"
								placeholder={ t( 'username' ) }
								{ ...register( "username", { required: true } ) }
								value={ username }
								onChange={ ( e: React.ChangeEvent<HTMLInputElement> ) => setUsername( e.target.value ) }
							/>
							{ errors.username && errors.username.type === "required" && (
								<div className="p-error">{ t( 'usernameError' ) }</div>
							) }
						</div>

						<div className="flex flex-column field mb-0">
							<InputText
								id="password"
								type="password"
								placeholder={ t( 'password' ) }
								{ ...register( "password", { required: true } ) }
							/>
							{ errors.password && errors.password.type === "required" && (
								<div className="p-error">{ t( 'passwordError' ) }</div>
							) }
						</div>
						<Button className="login-btn" type="submit" label={ t( 'login' ) } />
						<span className='span-margin'>
							{ t( 'inxs_login_legal_info' ) }
						</span>
					</form>
				</div>
			</div>
		</div>
	)
};

export default Login;
