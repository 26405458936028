import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddToHomeScreenPrompt } from '../hooks/useAddToHomeScreenPrompt';

const INSTALL_ALREADY_ASKED_KEY = 'INSTALL_ALREADY_ASKED_KEY'
const isAlreadyAskedToInstall = localStorage.getItem( INSTALL_ALREADY_ASKED_KEY );

const AddToHomeScreenPrompt = () => {
	const { t } = useTranslation();
	const [ prompt, promptToInstall ] = useAddToHomeScreenPrompt();

	const [ isVisible, setVisibleState ] = useState( false );

	const hide = useCallback( () => {
		setVisibleState( false );
		localStorage.setItem( INSTALL_ALREADY_ASKED_KEY, 'yes' );
	}, [] );

	React.useEffect( () => {
		if ( prompt && !isAlreadyAskedToInstall ) {
			setVisibleState( true );
		}
	}, [ prompt, setVisibleState ] );

	if ( !isVisible ) {
		return <div />;
	}

	return (
		<div className="install-prompt-container">
			<p className="install-prompt-text">{ t( 'To see INXS in fullscreen, you can add it to your home screen' ) }</p>
			<div className="prompt-actions" >
				<button className="close-button" onClick={ hide }>{ t( 'Close' ) }</button>
				<button className="install-prompt-button" onClick={ promptToInstall }>
					{ t( 'Add' ) }
				</button>
			</div>
		</div>
	);
};

export default AddToHomeScreenPrompt;
