import React, { ReactNode } from 'react';
import { Navigate } from "react-router-dom";
import { useRecoilValue } from 'recoil';
import { tokenState } from '../../atoms/auth';

interface GuardedComponentProps {
	children: ReactNode,
	redirectTo: string
}
const GuardedRoute: React.FC<GuardedComponentProps> = ( {
	children,
	redirectTo
} ) => {
	const token = useRecoilValue<string>( tokenState );
	return token ? <>{ children }</> : <Navigate to={ redirectTo } />
}

export default GuardedRoute;