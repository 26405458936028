import React from 'react';
import { useTranslation } from 'react-i18next';
export interface UpdateAppMessageProps {
	onUpdate: () => void;
}

const UpdateAppMessage: React.FC<UpdateAppMessageProps> = ( { onUpdate } ) => {
	const { t } = useTranslation();

	return (
		<div className="prompt-container">
			<div className="prompt-inner">
				<p className="prompt-text">
					{ t( 'A new version of INXS is available.' ) }
				</p>
				<div className={ 'update-button' } onClick={ onUpdate }>
					{ t( 'Update' ) }
				</div>
			</div>
		</div>
	);
};

export default UpdateAppMessage;
