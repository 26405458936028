import { LatLng } from 'leaflet';
import config from './../config-loader';

let temp = localStorage.getItem( 'authentication' ) ? JSON.parse( localStorage.getItem( 'authentication' ) || '' ) : {};
let authToken = temp.authTokenState || '';
const noConnectionError = "No connection";

// Login

export interface LoginArguments {
	email: string;
	password: string;
};

export interface SuccessfulLoginResponse {
	api_token: string;
	user: {
		name: string;
		email: string;
	};
	grid_operators: GridOperator[]
}
export interface GridOperator {
	id: number;
	name: string;
	logo_url: string;
	type: 'water' | 'gas' | 'electricity'
}

export interface FailedLoginResponse {
	message: string;
}

// Get Fields


export interface GetFieldsArguments {
	gridOperatorId: number;
}
export interface FieldsDefinition {
	[ key: string ]: FieldDefinition
}

export interface FieldDefinition {
	default: number | null;
	label: string;
	options: FieldOptions
	type: 'dropdown' | ''
}

export interface FieldOptions {
	[ key: number ]: string
}

// GetAddressArguments
export interface GetAddressArguments {
	latLng: LatLng
}

export interface GetAddressResults {
	country: string;
	municipality: string;
	settlement: string;
	postcodes: string[];
	street: string;
	streetnumber: string;
	lat: number;
	lng: number;
}

export interface FormFields {
	cause: string;
	cause_subset: string;
	equipment: string;
	grid_sort: string;
	origin: string;
	type_of_work: string;
	water_diameter?: string;
	water_grid_type?: string;
	water_material?: string;
	gas_diameter?: string;
	gas_grid_type?: string;
	gas_material?: string;
	electricity_diameter?: string;
	electricity_grid_type?: string;
	electricity_material?: string;
	work_type: string;
	street: string;
	house_number: string;
	city: string;
	postcode: string;
	circumstances: string;
	party_remarks: string;
	agree_remarks: string;
	commissioner: string;
	main_contractor: string;
	causative_party: string;
	contact_name: string;
	contact_email: string;
	contact_phone: string;
	previously_caused: string;
	previously_caused_date: string;
	notified_grid_operator: string;
	klic_notification: string;
	klic_at_work: string;
	klic_damaged_item: string;
	evides_email: string;
	item_localised: string;
	localisations_recorded: string;
	work_instructions: string;
	any_deviations: string;
	deviation_value: string;
}

export interface SaveFormParams {
	formFields: FormFields;
	gridOperatorId: string;
	mapScreenshot: string;
	equipmentImages: string[];
	closeUpImages: string[];
	surroundingImages: string[];
	otherImages: string[];
}

export interface SaveFormResponse {
	damage_report_id: string;
}

export type ImageCollection = 'damage-photos' | 'damage-maps';

export type ImageCategory = 'surroundings' | 'close-ups' | 'equipment' | 'others';

export interface SavePhotoParams {
	damageReportId: string;
	imageData: string;
	isLast: boolean;
	collection: ImageCollection;
	category?: ImageCategory;
}

export interface CategorizedImage {
	imageData: string;
	collection: ImageCollection;
	category?: ImageCategory;
}

export interface UploadImagesParams {
	damageReportId: string;
	images: CategorizedImage[]
}

const api = {
	login: async ( { email, password }: LoginArguments ) => {
		try {
			const response = await fetch( `${ config.apiUrl }tokens?email=${ email }&password=${ password }`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest'
				}
			} );


			if ( !response.ok ) {
				const errorMessage = await response.text();
				const data = JSON.parse( errorMessage )
				throw ( data.message );
			}
			else {
				const jsonRes: SuccessfulLoginResponse = await response.json();
				authToken = jsonRes.api_token;
				return jsonRes as SuccessfulLoginResponse;
			}
		}
		catch ( err ) {
			console.log( { err } );

			throw err;
		}
	},
	fields: async ( { gridOperatorId }: GetFieldsArguments ) => {
		try {
			// {{domain}}/{{api-version}}/grid-operators/{{grid-operator-id}}/fields
			const response = await fetch( `${ config.apiUrl }grid-operators/${ gridOperatorId }/fields`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Authorization': `Bearer ${ authToken }`
				},
			} );

			const jsonRes = await response.json();

			if ( !response.ok ) {
				throw jsonRes.message
			}

			return jsonRes as FieldsDefinition;
		}
		catch ( err: any ) {
			console.log( { err } );

			if ( { err }.err.message ) {
				throw noConnectionError;
			} else {
				throw err;
			}
		}
	},
	getAddress: async ( { latLng }: GetAddressArguments ) => {
		try {
			const addressResponse = await fetch( `https://api.pro6pp.nl/v2/reverse/global?authKey=${ config.geocodingApiKey }&latitude=${ latLng.lat }&longitude=${ latLng.lng }&precision=streetnumber` );

			const responseBody = await addressResponse.json();

			if ( responseBody.status === 'error' ) {
				throw responseBody.error.message;
			}

			return responseBody as GetAddressResults;
		}
		catch ( error ) {
			throw error;
		}
	},
	saveForm: async ( { formFields, gridOperatorId, equipmentImages, surroundingImages, closeUpImages, otherImages, mapScreenshot }: SaveFormParams ) => {
		try {
			// {{domain}}/{{api-version}}/grid-operators/{{grid-operator-id}}/damage-reports
			const response = await fetch( `${ config.apiUrl }grid-operators/${ gridOperatorId }/damage-reports`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Authorization': `Bearer ${ authToken }`
				},
				body: JSON.stringify( formFields )
			} );

			const jsonRes = await response.json();

			if ( !response.ok ) {
				throw jsonRes.message
			}

			const mapPhoto: CategorizedImage = {
				imageData: mapScreenshot,
				collection: 'damage-maps',
			}

			// Upload images

			const categorizedEquipmentImages: CategorizedImage[] = equipmentImages.map( imageData => ( {
				imageData,
				category: 'equipment',
				collection: 'damage-photos'
			} ) );

			const categorizedSurroundingsImages: CategorizedImage[] = surroundingImages.map( imageData => ( {
				imageData,
				category: 'surroundings',
				collection: 'damage-photos'
			} ) );

			const categorizedCloseUpsImages: CategorizedImage[] = closeUpImages.map( imageData => ( {
				imageData,
				category: 'close-ups',
				collection: 'damage-photos'
			} ) );

			const categorizedOtherImages: CategorizedImage[] = otherImages.map( imageData => ( {
				imageData,
				category: 'others',
				collection: 'damage-photos'
			} ) );

			const allImages = [ ...categorizedSurroundingsImages, ...categorizedCloseUpsImages, ...categorizedEquipmentImages, ...categorizedOtherImages ];

			await api.savePhoto( {
				...mapPhoto,
				damageReportId: jsonRes.damage_report_id,
				isLast: false
			} );

			if ( allImages.length ) {
				await api.uploadImages( {
					damageReportId: jsonRes.damage_report_id,
					images: allImages
				} )
			}


			return jsonRes as SaveFormResponse;
		}
		catch ( err: any ) {
			console.log( { err } );

			if ( { err }.err.message ) {
				throw noConnectionError;
			} else {
				throw err;
			}
		}
	},
	uploadImages: async ( { damageReportId, images }: UploadImagesParams ) => {
		for ( let imageIndex = 0; imageIndex < images.length; imageIndex++ ) {
			const image = images[ imageIndex ];
			const isLast = imageIndex === images.length - 1;

			await api.savePhoto( {
				damageReportId,
				imageData: image.imageData,
				category: image.category,
				collection: image.collection,
				isLast,
			} );
		}

	},
	savePhoto: async ( { damageReportId, category, collection, imageData, isLast }: SavePhotoParams ) => {
		try {
			// {{domain}}/{{api-version}}/damage-reports/{{damage-report-id}}/photos
			const response = await fetch( `${ config.apiUrl }damage-reports/${ damageReportId }/photos`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Authorization': `Bearer ${ authToken }`
				},
				body: JSON.stringify( {
					photo: imageData,
					is_last: isLast,
					category,
					collection
				} )
			} );
			const jsonRes = await response.json();

			if ( !response.ok ) {
				throw jsonRes.message
			}
			return jsonRes;
		}
		catch ( err: any ) {
			console.log( { err } );
			if ( { err }.err.message ) {
				throw noConnectionError;
			} else {
				throw err;
			}
		}
	}
};

export default api;