import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import './ConfirmationDialog.scss';

export interface ConfirmDialog {
	onHideDialog: ( e: any ) => void;
	visible: boolean;
	confirm: () => void;
	message: string;
}

const ConfirmationDialog: FC<ConfirmDialog> = ( { onHideDialog, visible, confirm, message } ) => {
	const { t } = useTranslation();
	const renderFooter = () => {
		return (
			<div className="flex justify-content-center">
				<Button label={ t( 'inxs_dialog_Ok' ) } onClick={ () => confirm() } autoFocus className="confirm-btn" />
			</div>
		);
	}
	return (
		<Dialog visible={ visible } onHide={ () => onHideDialog( false ) } footer={ renderFooter() } showHeader={ false }
			closable={ false }>
			<h4 className="text-center">{ t( message ) }</h4>
		</Dialog>
	)
};

export default ConfirmationDialog;