import { atom, RecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export enum ImageCategoryEnum {
	Surrounding = 'SURROUNDING',
	CloseUps = 'CLOSEUPS',
	Equipment = 'EQUIPMENT',
	Other = 'OTHER'
};

const { persistAtom } = recoilPersist( { key: 'imageInputs' } );

export interface ImageCategoryData {
	category: ImageCategoryEnum;
	images: string[],
};

export const surroundingImages = atom<ImageCategoryData>( {
	key: 'surroundingImages',
	default: {
		category: ImageCategoryEnum.Surrounding,
		images: []
	},
	effects_UNSTABLE: [ persistAtom ]
} )

export const closeUpsImages = atom<ImageCategoryData>( {
	key: 'closeUpsImages',
	default: {
		category: ImageCategoryEnum.CloseUps,
		images: []
	},
	effects_UNSTABLE: [ persistAtom ]
} )

export const equipmentImages = atom<ImageCategoryData>( {
	key: 'equipmentImages',
	default: {
		category: ImageCategoryEnum.Equipment,
		images: []
	},
	effects_UNSTABLE: [ persistAtom ]
} )

export const otherImages = atom<ImageCategoryData>( {
	key: 'otherImages',
	default: {
		category: ImageCategoryEnum.Other,
		images: []
	},
	effects_UNSTABLE: [ persistAtom ]
} )

export type ImageCategoryAtomsType = {
	[ key in ImageCategoryEnum ]: RecoilState<ImageCategoryData>
}

export const imageCategoryAtoms: ImageCategoryAtomsType = {
	[ ImageCategoryEnum.Surrounding ]: surroundingImages,
	[ ImageCategoryEnum.CloseUps ]: closeUpsImages,
	[ ImageCategoryEnum.Equipment ]: equipmentImages,
	[ ImageCategoryEnum.Other ]: otherImages
};