import { FC, useState, useEffect, useCallback, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import 'primeflex/primeflex.css';
import './DamageForm.scss';
import { useRecoilState, useRecoilValue } from 'recoil';
import { gridOperatorsState, selectedGridOperatorIdState } from './../../atoms/gridOperators';
import { defaultFormValue } from './../../atoms/damageForm';
import { resetLocationState } from './../../atoms/damageForm';
import api, { GridOperator } from '../../middleware/api';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import LocationFields from './LocationFields';
import RadioField from './RadioField';
import DropdownField from './DropdownFieldCustom';
import { useNavigate } from 'react-router-dom';

import {
	DropdownsOptions,
	DamageFormFields,
	newOptionForm,
	Address,
	DropdownsOpenFields,
	NewOptionsValues
} from './DamageFormTypes';
import { useResetState } from '../../hooks/useResetState';
import { damageFormState } from './../../atoms/damageForm';
import DamageImagesCapture from './DamageImagesCapture';
import { imageCategoryAtoms, ImageCategoryEnum } from '../../atoms/imageInputs';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';
//map screenshot
const snapshotOptions = {
	hidden: true,
	preventDownload: false,
};
const screenshotter = new SimpleMapScreenshoter( snapshotOptions );

const DamageForm: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { register: registerOption, handleSubmit: handleSubmitOption } = useForm<newOptionForm>();
	const gridOperators = useRecoilValue<GridOperator[]>( gridOperatorsState );
	const gridOperatorId = useRecoilValue<number>( selectedGridOperatorIdState )
	const resetState = useResetState();
	const [ , setResetLocation ] = useRecoilState<boolean>( resetLocationState );
	const [ error, setError ] = useState( '' );
	const [ displayWarningDialog, setDisplayWarningDialog ] = useState( false );

	const selectedGridOperator: GridOperator | undefined = gridOperators.filter( obj => {
		return obj.id === gridOperatorId;
	} )[ 0 ];
	const [ dropdownsOptions, setDropdownOptions ] = useState<DropdownsOptions>( {
		cause: [],
		cause_subset: [],
		equipment: [],
		material: [],
		diameter: [],
		grid_sort: [],
		grid_type: [],
		origin: [],
		type_of_work: [],
		work_type: []
	} )
	const [ diameterAllValuesArr, setDiameterAllValuesArr ] = useState<any[]>( [] );
	const [ damageForm, setDamageForm ] = useRecoilState<DamageFormFields>( damageFormState )
	const [ dropdownFieldsOpen, setDropdownFieldsOpen ] = useState<DropdownsOpenFields>(
		{
			cause: false,
			cause_subset: false,
			equipment: false,
			material: false,
			diameter: false,
			grid_sort: false,
			grid_type: false,
			origin: false,
			type_of_work: false,
			work_type: false
		} )
	const [ displayDialog, setDisplayDialog ] = useState( false );
	const [ displayConfirmDialog, setDisplayConfirmDialog ] = useState( false );
	const [ activeDropdownField, setActiveDropdownField ] = useState<string>( '' );
	const [ message, setMessage ] = useState<string>( '' );
	const arrDropdownFields = [ 'grid_sort', 'origin', 'grid_type', 'material', 'diameter', 'cause', 'cause_subset', 'type_of_work', 'equipment' ]
	const [ arrFieldsNewOptionAdded, setArrFieldsNewOptionAdded ] = useState<string[]>( [] );
	const [ newOptionsValues, setNewOptionsValues ] = useState<NewOptionsValues>( {
		'type_of_work': undefined,
		'equipment': undefined,
		'work_type': undefined
	} );

	const [ surroundingImages, setSurroundingImages ] = useRecoilState(
		imageCategoryAtoms[ ImageCategoryEnum.Surrounding ]
	)

	const [ closeUpImages, setCloseUpImages ] = useRecoilState(
		imageCategoryAtoms[ ImageCategoryEnum.CloseUps ]
	)

	const [ equipmentImages, setEquipmentImages ] = useRecoilState(
		imageCategoryAtoms[ ImageCategoryEnum.Equipment ]
	)

	const [ otherImages, setOtherImages ] = useRecoilState(
		imageCategoryAtoms[ ImageCategoryEnum.Other ]
	)

	const [ isSubmitting, setIsSubmitting ] = useState( false );
	const mapRef = useRef<any>( null );
	const map = mapRef.current;

	// console.log( damageForm );
	useEffect( () => {
		if ( map ) {
			screenshotter.addTo( map );
		}
	}, [ map ] );
	useEffect( () => {
		( async () => {
			api.fields( { gridOperatorId: gridOperatorId } )
				.then( fieldsDefinition => {
					let dropdownsOptionsNew: any = {};
					Object.entries( fieldsDefinition ).forEach( prop => {
						//handling different fields depending on grid operator type-create shared field name
						if ( prop[ 0 ] === 'electricity_material' || prop[ 0 ] === 'water_material' || prop[ 0 ] === 'gas_material' ) {
							dropdownsOptionsNew[ 'material' ] = Object.entries( prop[ 1 ].options ).map( ( [ k, v ] ) => ( { name: v, value: k } ) );
						}
						else if ( prop[ 0 ] === 'electricity_diameter' || prop[ 0 ] === 'water_diameter' || prop[ 0 ] === 'gas_diameter' ) {
							dropdownsOptionsNew[ 'diameter' ] = Object.entries( prop[ 1 ].options ).map( ( [ k, v ] ) => ( { name: v, value: k } ) );
							setDiameterAllValuesArr( Object.entries( prop[ 1 ].options ).map( ( [ k, v ] ) => ( { name: v, value: k } ) ) );
						}
						else if ( prop[ 0 ] === 'electricity_grid_type' || prop[ 0 ] === 'water_grid_type' || prop[ 0 ] === 'gas_grid_type' ) {
							dropdownsOptionsNew[ 'grid_type' ] = Object.entries( prop[ 1 ].options ).map( ( [ k, v ] ) => ( { name: v, value: k } ) );
						}
						//dynamic fields-add option for user input
						else if ( prop[ 0 ] === 'equipment' || prop[ 0 ] === 'work_type' || prop[ 0 ] === 'type_of_work' ) {
							dropdownsOptionsNew[ `${ prop[ 0 ] }` ] = Object.entries( prop[ 1 ].options ).map( ( [ k, v ] ) => ( { name: v, value: k } ) );
							dropdownsOptionsNew[ `${ prop[ 0 ] }` ].unshift( { name: t( '+ add new' ), value: 'new' } );
						}
						else {
							dropdownsOptionsNew[ `${ prop[ 0 ] }` ] = Object.entries( prop[ 1 ].options ).map( ( [ k, v ] ) => ( { name: v, value: k } ) );
							//set default value if exists
							if ( prop[ 1 ].default ) {
								setDamageForm( ( damageForm: any ) => {
									if ( !damageForm[ `${ prop[ 0 ] }` ] || damageForm[ `${ prop[ 0 ] }` ] === prop[ 1 ].default?.toString() ) {

										return ( { ...damageForm, [ `${ prop[ 0 ] }` ]: prop[ 1 ].default?.toString() } )
									}
									return { ...damageForm }
								} )
							}
						}
					} )
					setDropdownOptions( dropdownsOptionsNew )
				} )
				.catch( e => {
					console.log( e )
					if ( e === "Unauthenticated." ) {
						showConfirmDialog( 'Session expired' )
					} else {
						setError( e );
					}
				} );
		} )();
	}, [ gridOperatorId, setDamageForm, t ] );


	const onSubmit = ( e: any ) => {
		e.preventDefault()

		let emptyField: boolean = false;
		for ( const [ item, value ] of Object.entries( damageForm ) ) {
			if ( value === '' ) {
				switch ( item ) {
					case "evides_email":
						if ( gridOperatorId === 1 && damageForm[ 'grid_type' ] === "9" ) {
							emptyField = true;
						}
						break;
					case "previously_caused_date":
						if ( damageForm[ 'previously_caused' ] === '1' ) {
							emptyField = true;
						}
						break;
					case "deviation_value":
						if ( damageForm[ 'any_deviations' ] === '1' ) {
							emptyField = true;
						}
						break;
					default:
						emptyField = true;
				}

			}
		}
		if ( emptyField ) {
			setDisplayWarningDialog( true );
		} else {
			submitData()
		}

	}
	const submitData = async () => {
		setIsSubmitting( true );

		try {
			const mapScreenshot = await takeScreenShot();
			const { material, diameter, grid_type, ...damageReportData } = {
				...damageForm,
				[ `${ selectedGridOperator.type }_material` ]: damageForm[ 'material' ],
				[ `${ selectedGridOperator.type }_diameter` ]: damageForm[ 'diameter' ],
				[ `${ selectedGridOperator.type }_grid_type` ]: damageForm[ 'grid_type' ],
				'new_field_options': arrFieldsNewOptionAdded
			}
			setDamageForm( ( _ ) => {
				return ( { material, diameter, grid_type, ...damageReportData } )
			} )

			api.saveForm( {
				formFields: damageReportData,
				gridOperatorId: gridOperatorId.toString(),
				equipmentImages: equipmentImages.images,
				otherImages: otherImages.images,
				closeUpImages: closeUpImages.images,
				surroundingImages: surroundingImages.images,
				mapScreenshot
			} ).then( response => {
				setIsSubmitting( false );
				setEquipmentImages( { ...equipmentImages, images: [] } );
				setOtherImages( { ...otherImages, images: [] } );
				setSurroundingImages( { ...surroundingImages, images: [] } );
				setCloseUpImages( { ...closeUpImages, images: [] } );

				console.log( { response } );
				showConfirmDialog( t( 'Damage report successfully added' ) );
			} ).catch( e => {
				setIsSubmitting( false );

				if ( e === "Unauthenticated." ) {
					showConfirmDialog( 'Session expired' )
				} else {
					setError( e );
				}
			} );
		}
		catch ( screenshotError: any ) {
			setError( screenshotError )
			console.error( { screenshotError } );
		}
	}

	const onChange = ( e: any ) => {
		switch ( e.target.name ) {
			case "previously_caused":
				if ( e.target.value === '0' ) {
					setDamageForm( damageForm => ( { ...damageForm, 'previously_caused_date': '' } ) )
				}
				setDamageForm( damageForm => ( { ...damageForm, [ `${ e.target.name }` ]: e.target.value } ) )
				break;
			case "previously_caused_unknown":
				setDamageForm( damageForm => ( { ...damageForm, previously_caused_date: null } ) )
				break;
			case "agree_remarks":
				setDamageForm( damageForm => ( { ...damageForm, [ `${ e.target.name }` ]: e.checked ? '1' : '0' } ) )
				break;
			default:
				setDamageForm( damageForm => ( { ...damageForm, [ `${ e.target.name }` ]: e.target.value } ) )
		}
	}

	const onDropdownChange = ( e: any, name: string ) => {
		let nextItem = arrDropdownFields.indexOf( name ) >= 0 ? arrDropdownFields[ arrDropdownFields.indexOf( name ) + 1 ] : null;
		switch ( name ) {
			case "equipment":
			case "work_type":
			case "type_of_work":
				if ( e.value === 'new' ) {
					openDialog( name )
				} else {
					//send to BE array of all fields that has new options added by user
					if ( !e.state ) {
						let updatedArr = arrFieldsNewOptionAdded.filter( function ( item ) {
							return item !== name
						} )
						setNewOptionsValues( newOptionsValues => ( { ...newOptionsValues, [ `${ name }` ]: undefined } ) )
						setArrFieldsNewOptionAdded( updatedArr );
					}
					setDamageForm( damageForm => ( { ...damageForm, [ `${ name }` ]: e.value } ) )
					setDropdownFieldsOpen( dropdownFieldsOpen => ( { ...dropdownFieldsOpen, [ `${ name }` ]: false } ) );
					setDropdownFieldsOpen( dropdownFieldsOpen => ( { ...dropdownFieldsOpen, [ `${ nextItem }` ]: true } ) );
				}
				break;
			case "diameter":
				setDamageForm( damageForm => ( { ...damageForm, [ `${ name }` ]: e.value } ) )
				setDropdownFieldsOpen( dropdownFieldsOpen => ( { ...dropdownFieldsOpen, [ `${ name }` ]: false } ) );
				setDropdownFieldsOpen( dropdownFieldsOpen => ( { ...dropdownFieldsOpen, 'type_of_work': true } ) );
				break;
			case "grid_type":
				let filtered: any[] = [];
				let defaultValue: string = '';
				switch ( e.value ) {
					case "7":
						filtered = ( diameterAllValuesArr ).filter( function ( item ) {
							return ( parseInt( item.value ) >= 49 && parseInt( item.value ) <= 57 );
						} );
						defaultValue = '53';
						break;
					case "8":
						filtered = ( diameterAllValuesArr ).filter( function ( item ) {
							return ( parseInt( item.value ) >= 44 && parseInt( item.value ) <= 54 );
						} );
						defaultValue = '49';
						break;
					case "9":
						filtered = ( diameterAllValuesArr ).filter( function ( item ) {
							return ( parseInt( item.value ) >= 41 && parseInt( item.value ) <= 48 );
						} );
						defaultValue = '43';
						break;
					default:
						filtered = diameterAllValuesArr;
				}
				setDamageForm( damageForm => ( { ...damageForm, 'diameter': defaultValue } ) )
				setDropdownOptions( prevDropdown => ( {
					...prevDropdown,
					'diameter': filtered
				} ) );
				setDamageForm( damageForm => ( { ...damageForm, [ `${ name }` ]: e.value } ) )
				setDropdownFieldsOpen( dropdownFieldsOpen => ( { ...dropdownFieldsOpen, [ `${ name }` ]: false } ) );
				setDropdownFieldsOpen( dropdownFieldsOpen => ( { ...dropdownFieldsOpen, [ `${ nextItem }` ]: true } ) );
				break;
			default:
				setDamageForm( damageForm => ( { ...damageForm, [ `${ name }` ]: e.value } ) )
				setDropdownFieldsOpen( dropdownFieldsOpen => ( { ...dropdownFieldsOpen, [ `${ name }` ]: false } ) );
				setDropdownFieldsOpen( dropdownFieldsOpen => ( { ...dropdownFieldsOpen, [ `${ nextItem }` ]: true } ) );
		}
	}
	const closeAll = () => {
		setDropdownFieldsOpen( {
			cause: false,
			cause_subset: false,
			equipment: false,
			material: false,
			diameter: false,
			grid_sort: false,
			grid_type: false,
			origin: false,
			type_of_work: false,
			work_type: false
		} )
	}
	const onLocationChange = useCallback( ( location: Address ) => {
		Object.entries( location ).forEach( item => {
			setDamageForm( damageForm => ( { ...damageForm, [ `${ item[ 0 ] }` ]: item[ 1 ] } ) )
		} )
	}, [ setDamageForm ] );

	const addOptionToDropdown = ( data: newOptionForm ) => {
		switch ( activeDropdownField ) {
			case "equipment":
			case "work_type":
			case "type_of_work":
				//allow just one new value to be added (remove if other behaviour required)
				let tempDropdown = dropdownsOptions[ activeDropdownField ].filter( obj => {
					return obj.state !== 'additional'
				} )
				tempDropdown.push( { name: data.option, value: 'new-' + data.option, state: 'additional' } )

				//send to BE array of all fields that has new options added by user
				if ( arrFieldsNewOptionAdded.indexOf( activeDropdownField ) < 0 ) {
					setArrFieldsNewOptionAdded( arrFieldsNewOptionAdded => ( [ ...arrFieldsNewOptionAdded, activeDropdownField ] ) );
				}
				setNewOptionsValues( newOptionsValues => ( { ...newOptionsValues, [ `${ activeDropdownField }` ]: 'new-' + data.option } ) )

				setDropdownOptions( prevDropdown => ( {
					...prevDropdown,
					//allow more than one new value to be added -uncomment if needed
					// [ `${ activeDropdownField }` ]: [ ...dropdownsOptions[ activeDropdownField ], { name: data.option, value: data.option, state: 'additional' } ]
					[ `${ activeDropdownField }` ]: tempDropdown
				} ) );
				let nextItem = arrDropdownFields.indexOf( activeDropdownField ) > 0 ? arrDropdownFields[ arrDropdownFields.indexOf( activeDropdownField ) + 1 ] : null;
				setDamageForm( damageForm => ( { ...damageForm, [ `${ activeDropdownField }` ]: data.option } ) )
				setDropdownFieldsOpen( dropdownFieldsOpen => ( { ...dropdownFieldsOpen, [ `${ activeDropdownField }` ]: false } ) );
				setDropdownFieldsOpen( dropdownFieldsOpen => ( { ...dropdownFieldsOpen, [ `${ nextItem }` ]: true } ) );
				break;
			default:
		}
		hideDialog()
	}
	const openDialog = ( field: string ) => {
		setDisplayDialog( true );
		setActiveDropdownField( field )
	}

	const hideDialog = () => {
		setDisplayDialog( false );
	}
	const showConfirmDialog = ( message: string ) => {
		setDisplayConfirmDialog( true );
		setMessage( message );
	}

	const hideConfirmDialog = () => {
		setDisplayConfirmDialog( false );
	}
	const logOut = useCallback( () => {
		localStorage.removeItem( 'authentication' );
		localStorage.removeItem( 'gridOperators' );
		resetState()
		setDisplayConfirmDialog( false );
	}, [ resetState, setDisplayConfirmDialog ] );

	// Auto-hide the success dialog (and navigate)
	useEffect( () => {
		if ( displayConfirmDialog ) {
			setTimeout( () => {
				logOut();
			}, 3000 );
		}
	}, [ displayConfirmDialog, logOut ] );

	const takeScreenShot = () =>
		screenshotter
			.takeScreen( "image" )
			.then( ( image ) => {
				return image.toString();
			} );

	const resetForm = () => {
		if ( window.confirm( t( `You are about to clear all the data that was filled in.` ) ) ) {
			setDamageForm( defaultFormValue )

			setSurroundingImages( {
				category: ImageCategoryEnum.Surrounding,
				images: []
			} );
			setCloseUpImages( {
				category: ImageCategoryEnum.CloseUps,
				images: []
			} );
			setEquipmentImages( {
				category: ImageCategoryEnum.Equipment,
				images: []
			} );
			setOtherImages( {
				category: ImageCategoryEnum.Other,
				images: []
			} );

			setResetLocation( true )
		}

	}

	const goBack = () => {
		if ( gridOperators.length > 1 ) {
			navigate( '/grid-operators' );
		} else {
			logOut()
		}
	}

	return (
		<div className="flex flex-column align-items-center justify-content-center input-filled">
			<div className="top-controls">
				<div className="top-control-left">
					<Button
						className="p-button-text"
						icon="pi pi-chevron-left"
						label={ t( 'Back' ) }
						onClick={ goBack } />
				</div>
				<div className="top-control-center">
					{ selectedGridOperator?.name }
				</div>
				<div className="top-control-right">
					<Button
						className="p-button-text p-button-danger"
						icon="pi pi-minus-circle"
						label={ t( 'Clear form' ) }
						onClick={ resetForm } />
				</div>
			</div>

			<div className="flex flex-column align-items-center damage-form-container">

				<form className="flex flex-column align-items-center" onSubmit={ onSubmit }>
					<div className="top-container flex flex-column align-items-center justify-content-between flex-wrap">
						<LocationFields
							houseNumber={ damageForm[ 'house_number' ] }
							city={ damageForm[ 'city' ] }
							postcode={ damageForm[ 'postcode' ] }
							street={ damageForm[ 'street' ] }
							onLocationChange={ onLocationChange }
							mapRef={ mapRef }
						/>
					</div>

					<div className="section-title">
						<Divider />
					</div>
					<DamageImagesCapture />
					<div className="section-title">
						<Divider />
					</div>
					<div className="section flex flex-row align-items-start justify-content-between flex-wrap">
						<DropdownField
							dropdownOptions={ dropdownsOptions[ 'grid_sort' ] }
							value={ damageForm[ 'grid_sort' ] }
							name={ 'grid_sort' }
							label={ t( 'grid sort' ) }
							onChange={ onDropdownChange }
							fieldOpen={ dropdownFieldsOpen[ 'grid_sort' ] }
							closeAll={ closeAll } />
						<DropdownField
							dropdownOptions={ dropdownsOptions[ 'origin' ] }
							value={ damageForm[ 'origin' ] }
							name={ 'origin' }
							label={ t( 'origin' ) }
							onChange={ onDropdownChange }
							fieldOpen={ dropdownFieldsOpen[ 'origin' ] }
							closeAll={ closeAll } />
					</div>
					<div className="section section-triple flex flex-row align-items-start justify-content-between flex-wrap">
						<DropdownField
							dropdownOptions={ dropdownsOptions[ 'grid_type' ] }
							value={ damageForm[ 'grid_type' ] }
							name={ 'grid_type' }
							label={ t( 'grid type' ) }
							onChange={ onDropdownChange }
							fieldOpen={ dropdownFieldsOpen[ 'grid_type' ] }
							closeAll={ closeAll } />
						<DropdownField
							dropdownOptions={ dropdownsOptions[ 'material' ] }
							value={ damageForm[ 'material' ] }
							name={ 'material' }
							label={ t( 'material' ) }
							onChange={ onDropdownChange }
							fieldOpen={ dropdownFieldsOpen[ 'material' ] }
							closeAll={ closeAll } />
						<DropdownField
							dropdownOptions={ dropdownsOptions[ 'diameter' ] }
							value={ damageForm[ 'diameter' ] }
							name={ 'diameter' }
							label={ t( 'diameter' ) }
							onChange={ onDropdownChange }
							fieldOpen={ dropdownFieldsOpen[ 'diameter' ] }
							closeAll={ closeAll } />
					</div>
					<div className="section-title">
						<Divider />
					</div>
					<div className="section flex flex-row align-items-start justify-content-between flex-wrap">
						<DropdownField
							dropdownOptions={ dropdownsOptions[ 'cause' ] }
							value={ damageForm[ 'cause' ] }
							name={ 'cause' }
							label={ t( 'cause' ) }
							onChange={ onDropdownChange }
							fieldOpen={ dropdownFieldsOpen[ 'cause' ] }
							closeAll={ closeAll } />
						<DropdownField
							dropdownOptions={ dropdownsOptions[ 'cause_subset' ] }
							value={ damageForm[ 'cause_subset' ] }
							name={ 'cause_subset' }
							label={ t( 'cause subset' ) }
							onChange={ onDropdownChange }
							fieldOpen={ dropdownFieldsOpen[ 'cause_subset' ] }
							closeAll={ closeAll } />
						<DropdownField
							dropdownOptions={ dropdownsOptions[ 'type_of_work' ] }
							value={ newOptionsValues[ 'type_of_work' ] ? newOptionsValues[ 'type_of_work' ] : damageForm[ 'type_of_work' ] }
							name={ 'type_of_work' }
							label={ t( 'type of work' ) }
							onChange={ onDropdownChange }
							fieldOpen={ dropdownFieldsOpen[ 'type_of_work' ] }
							closeAll={ closeAll } />
						<DropdownField
							dropdownOptions={ dropdownsOptions[ 'equipment' ] }
							value={ newOptionsValues.equipment ? newOptionsValues.equipment : damageForm[ 'equipment' ] }
							name={ 'equipment' }
							label={ t( 'equipment' ) }
							onChange={ onDropdownChange }
							fieldOpen={ dropdownFieldsOpen[ 'equipment' ] }
							closeAll={ closeAll } />
						<div className="flex flex-column field mb-0">
							<label className="mb-2 flex-item" htmlFor="circumstances">{ t( 'Circumstances' ) }</label>
							<div className="flex-item">
								<InputTextarea rows={ 5 } cols={ 30 }
									value={ damageForm[ 'circumstances' ] }
									onChange={ onChange }
									id="circumstances"
									name="circumstances"
									className={ damageForm[ 'circumstances' ] ? '' : 'empty' }
								/>
							</div>
						</div>
						<div className="flex flex-column field mb-0">
							<div className="flex flex-row justify-content-between">
								<label className="mb-2 flex-item" htmlFor="party_remarks">{ t( 'Party remarks' ) }:</label>
								<div className="flex flex-row">
									<label className="mb-2 mr-2 flex-item" htmlFor="agree_remarks">{ t( 'Agree' ) }</label>
									<Checkbox className={ damageForm[ 'party_remarks' ] ? '' : 'empty' } inputId="agree_remarks" name="agree_remarks" checked={ damageForm[ 'agree_remarks' ] === '1' } onChange={ onChange } ></Checkbox>
								</div>
							</div>
							<div className="flex-item">
								<InputTextarea rows={ 5 } cols={ 30 }
									value={ damageForm[ 'party_remarks' ] }
									onChange={ onChange }
									id="party_remarks"
									name="party_remarks"
									className={ damageForm[ 'party_remarks' ] ? '' : 'empty' }
								/>
							</div>
						</div>

					</div>
					<div className="section-title">
						<Divider />
					</div>
					<div className="section flex flex-row align-items-start justify-content-between flex-wrap">
						{/* example layout for the new change request */ }
						<div className="flex flex-column field mb-0 half-section">
							<div className="flex flex-column field mb-0">
								<label className="mb-2 flex-item" htmlFor="commissioner">{ t( 'Assigner' ) }</label>
								<div className="flex-item">
									<InputText
										id="commissioner"
										type="text"
										name="commissioner"
										value={ damageForm[ 'commissioner' ] }
										onChange={ onChange }
										className={ damageForm[ 'commissioner' ] ? '' : 'empty' }
									/>
								</div>
							</div>
							<div className="flex flex-column field mb-0">
								<label className="mb-2 flex-item" htmlFor="main_contractor">{ t( 'Main contractor' ) }</label>
								<div className="flex-item">
									<InputText
										value={ damageForm[ 'main_contractor' ] }
										id="main_contractor"
										type="text"
										name="main_contractor"
										onChange={ onChange }
										className={ damageForm[ 'main_contractor' ] ? '' : 'empty' }
									/>
								</div>
							</div>
							<div className="flex flex-column field mb-0">
								<label className="mb-2 flex-item" htmlFor="causative_party">{ t( 'Causative party' ) }</label>
								<div className="flex-item">
									<InputText
										value={ damageForm[ 'causative_party' ] }
										id="causative_party"
										type="text"
										name="causative_party"
										onChange={ onChange }
										className={ damageForm[ 'causative_party' ] ? '' : 'empty' }
									/>
								</div>
							</div>
						</div>
						<div className="flex flex-column field mb-0 half-section">
							<div className="flex flex-column field mb-0">
								<label className="mb-2 flex-item" htmlFor="contact_name">{ t( 'Contact name' ) }:</label>
								<div className="flex-item">
									<InputText
										value={ damageForm[ 'contact_name' ] }
										id="contact_name"
										type="text"
										name="contact_name"
										onChange={ onChange }
										className={ damageForm[ 'contact_name' ] ? '' : 'empty' }
									/>
								</div>
							</div>
							<div className="flex flex-column field mb-0">
								<label className="mb-2 flex-item" htmlFor="contact_email">{ t( 'Email' ) }</label>
								<div className="flex-item">
									<InputText
										value={ damageForm[ 'contact_email' ] }
										id="contact_email"
										type="email"
										name="contact_email"
										onChange={ onChange }
										className={ damageForm[ 'contact_email' ] ? '' : 'empty' }
									/>
								</div>
							</div>
							<div className="flex flex-column field mb-0">
								<label className="mb-2 flex-item" htmlFor="contact_phone">{ t( 'Telephone' ) }</label>
								<div className="flex-item">
									<InputText
										value={ damageForm[ 'contact_phone' ] }
										id="contact_phone"
										type="tel"
										name="contact_phone"
										onChange={ onChange }
										className={ damageForm[ 'contact_phone' ] ? '' : 'empty' }
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="section-title">
						<Divider />
					</div>
					<div className="section flex flex-row align-items-start justify-content-between flex-wrap">
						<RadioField
							inputIdYes="prevYes"
							inputIdNo="prevNo"
							name="previously_caused"
							label={ t( 'previously caused' ) }
							radioFieldValue={ damageForm[ 'previously_caused' ] }
							onChange={ onChange } />

						<div className="flex flex-column radio-field" style={ { visibility: damageForm[ 'previously_caused' ] === '1' ? 'visible' : 'hidden' } }>
							<label className="mb-2 flex-item" htmlFor="previously_caused_date">{ t( 'When' ) }</label>
							<div className="mr-2 flex-item flex flex-row">
								<div className="mr-2 flex flex-row" >
									<Calendar
										inputId="previously_caused_date"
										showIcon
										name="previously_caused_date"
										className="flex-item"
										onChange={ onChange }
										value={ new Date( damageForm[ 'previously_caused_date' ] ) }
									/>
								</div>
								<div className="mr-2 p-ml-4 align-items-center flex flex-row">
									<RadioButton
										inputId="unknownYes"
										className="mr-2"
										name="previously_caused_unknown"
										onChange={ onChange }
										checked={ damageForm[ 'previously_caused_date' ] === null && damageForm[ 'previously_caused' ] === "1" } />
									<label htmlFor="unknownYes" className="mr-2">{ t( 'Unknown' ) }</label>
								</div>
							</div>
						</div>
						<DropdownField
							dropdownOptions={ dropdownsOptions[ 'work_type' ] }
							value={ newOptionsValues[ 'work_type' ] ? newOptionsValues[ 'work_type' ] : damageForm[ 'work_type' ] }
							name={ 'work_type' }
							label={ t( 'work type' ) }
							onChange={ onDropdownChange }
							fieldOpen={ dropdownFieldsOpen[ 'work_type' ] }
							closeAll={ closeAll } />
					</div>
					<div className="section flex flex-row align-items-start justify-content-between flex-wrap">
						<div className="flex flex-column field mb-0">
							<label className="mb-2 flex-item" htmlFor="klic_notification">{ t( 'KLIC notification' ) }</label>
							<div className="flex-item">
								<InputText
									value={ damageForm[ 'klic_notification' ] }
									id="klic_notification"
									type="text"
									name="klic_notification"
									onChange={ onChange }
									className={ damageForm[ 'klic_notification' ] ? '' : 'empty' }
								/>
							</div>
						</div>

						<RadioField
							inputIdYes='klicYes'
							inputIdNo='klicNo'
							name='klic_at_work'
							label={ t( 'KLIC at work' ) }
							radioFieldValue={ damageForm[ 'klic_at_work' ] }
							onChange={ onChange } />
						<RadioField
							inputIdYes='damagedYes'
							inputIdNo='damagedNo'
							name='klic_damaged_item'
							label={ t( 'Damaged item on KLIC' ) }
							radioFieldValue={ damageForm[ 'klic_damaged_item' ] }
							onChange={ onChange } />
						<RadioField
							inputIdYes='notifiedYes'
							inputIdNo='notifiedNo'
							name='notified_grid_operator'
							label={ t( 'Notified grid operator' ) }
							radioFieldValue={ damageForm[ 'notified_grid_operator' ] }
							onChange={ onChange } />
						<div className="inline-section flex flex-row align-items-start justify-content-start flex-wrap field mb-0">
							<RadioField
								inputIdYes='deviationsYes'
								inputIdNo='deviationsNo'
								name='any_deviations'
								label={ t( 'Any deviations' ) }
								radioFieldValue={ damageForm[ 'any_deviations' ] }
								onChange={ onChange } />
							<div className="flex flex-row custom-field align-items-end" style={ { visibility: damageForm[ 'any_deviations' ] === '1' ? 'visible' : 'hidden' } }>
								<InputText
									id="deviation_value"
									type="number"
									name="deviation_value"
									onChange={ onChange }
									value={ damageForm[ 'deviation_value' ] }
									className={ damageForm[ 'deviation_value' ] ? '' : 'empty' }
								/>
								<span>cm</span>
							</div>
						</div>
						<RadioField
							inputIdYes='emailYes'
							inputIdNo='emailNo'
							name='evides_email'
							label={ t( 'Email to Evides' ) }
							radioFieldValue={ damageForm[ 'evides_email' ] }
							onChange={ onChange }
							styles={ { visibility: selectedGridOperator && gridOperatorId === 1 && damageForm[ 'grid_type' ] === "9" ? 'visible' : 'hidden' } } />

						<RadioField
							inputIdYes='localisedYes'
							inputIdNo='localisedNo'
							name='item_localised'
							label={ t( 'Item localised' ) }
							radioFieldValue={ damageForm[ 'item_localised' ] }
							onChange={ onChange } />
						<RadioField
							inputIdYes='recordedYes'
							inputIdNo='recordedNo'
							name='localisations_recorded'
							label={ t( 'Localisations recorded' ) }
							radioFieldValue={ damageForm[ 'localisations_recorded' ] }
							onChange={ onChange } />
						<RadioField
							inputIdYes='actionPlanYes'
							inputIdNo='actionPlanNo'
							name='action_plan'
							label={ t( 'Action plan' ) }
							radioFieldValue={ damageForm[ 'action_plan' ] }
							onChange={ onChange } />

						<RadioField
							inputIdYes='instructionsYes'
							inputIdNo='instructionsNo'
							name='work_instructions'
							label={ t( 'Work instructions' ) }
							radioFieldValue={ damageForm[ 'work_instructions' ] }
							onChange={ onChange } />

					</div>
					<div className="flex mt-4 section flex-row-reverse">
						<Button icon="pi pi-send" type="button" className={ `submit-btn ${ isSubmitting ? 'loading' : '' }` } label={ t( 'Submit' ) } onClick={ onSubmit } />
					</div>
					{ error ? (
						<div className="mb-2 p-ta error-message">
							{ t( 'Damage report failed. Error' ) }: { error }
						</div>
					) : null }
				</form>
			</div>
			{/* dialog container for add new option to dropdown */ }
			<Dialog className="dialog-container" header={ t( 'Add new option' ) } visible={ displayDialog } style={ { width: '40vw' } } onHide={ () => hideDialog() }>
				<form className="flex flex-column align-items-end" onSubmit={ handleSubmitOption( addOptionToDropdown ) }>
					<InputText
						id="addOption"
						{ ...registerOption( "option", { required: true } ) }
					/>
					<div className="flex mt-4 flex-row align-items-end">
						<Button label="No" icon="pi pi-times" type="button" onClick={ () => hideDialog() } className="p-button-text" />
						<Button label="Yes" icon="pi pi-check" type="submit" autoFocus />
					</div>
				</form>
			</Dialog>

			{/* successfully submit form message			 */ }
			<ConfirmationDialog
				onHideDialog={ hideConfirmDialog }
				visible={ displayConfirmDialog }
				confirm={ logOut }
				message={ message } />

			{/* confirm when empty fields			 */ }
			<ConfirmDialog
				acceptLabel={ t( 'Yes' ) }
				rejectLabel={ t( 'No' ) }
				visible={ displayWarningDialog }
				icon="pi pi-info-circle"
				onHide={ () => setDisplayWarningDialog( false ) }
				message={ t( 'Not all fields are filled in.\n Do you want to submit it anyway?' ) }
				accept={ submitData }
				className="warning-dialog" />
		</div >
	)
};

export default DamageForm;