import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'primereact/radiobutton';

export interface RadioFieldProps {
	onChange: ( e: any ) => void;
	radioFieldValue: string;
	name: string;
	inputIdYes: string;
	inputIdNo: string;
	label: string;
	styles?: React.CSSProperties;
}

const RadioField: FC<RadioFieldProps> = ( { onChange, radioFieldValue, name, inputIdYes, inputIdNo, label, styles } ) => {
	const { t } = useTranslation();
	const RadioStyle: React.CSSProperties = { ...styles }
	return (
		<div className={ radioFieldValue ? 'flex flex-column radio-field' : 'flex flex-column radio-field empty' }
			style={ RadioStyle }>
			<label className="mb-3 flex-item" htmlFor={ name }>{ t( `${ label }` ) }</label>
			<div className="mb-1 flex-item flex flex-row">
				<div className="mr-2 flex flex-row" >
					<RadioButton inputId={ inputIdYes } className="mr-2" value="1" name={ name } onChange={ onChange } checked={ radioFieldValue === '1' } />
					<label htmlFor={ inputIdYes } className="mr-2">{ t( 'Yes' ) }</label>
				</div>
				<div className="mr-2 flex flex-row" >
					<RadioButton inputId={ inputIdNo } className="mr-2" value="0" name={ name } onChange={ onChange } checked={ radioFieldValue === '0' } />
					<label htmlFor={ inputIdNo } className="mr-2">{ t( 'No' ) }</label>
				</div>
			</div>
		</div>
	)
};

export default RadioField;