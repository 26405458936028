export const fileToBase64String = ( file: any ) =>
	new Promise( ( resolve, reject ) => {
		const reader = new FileReader();
		reader.readAsDataURL( file );
		reader.onload = () => {
			const index = ( reader.result as any ).indexOf( ';base64,' );

			if ( index === -1 ) {
				reject( new Error( 'Invalid file data' ) );
			}

			resolve( ( reader.result as any ).substring( index + 8 ) );
		};

		reader.onerror = reject;
	} );
