import { FC, useEffect, useState } from "react";
import { useSelect } from "downshift";
import './DropdownFieldCustom.scss';
import { useTranslation } from 'react-i18next';
export interface DropdownFieldProps {
	dropdownOptions: any;
	onChange: ( e: any, name: string ) => void;
	value: string;
	name: string;
	label: string;
	fieldOpen: boolean;
	closeAll: () => void;
}
const DropdownField: FC<DropdownFieldProps> = ( { dropdownOptions, onChange, value, name, label, fieldOpen, closeAll } ) => {
	const { t } = useTranslation();
	const items = dropdownOptions ? dropdownOptions : [];
	const defaultValue = value;
	const [ isOpen, setIsOpen ] = useState<boolean | undefined>( false );

	useEffect( () => {
		setIsOpen( fieldOpen )
	}, [ fieldOpen ] );
	const selectedItem = items && items.find( ( item: any ) => item.value === defaultValue ) ? items.find( ( item: any ) => item.value === value ) : '';
	const {
		getToggleButtonProps,
		getMenuProps,
		highlightedIndex,
		getItemProps
	} = useSelect( {
		items,
		onIsOpenChange: changes => {
			setIsOpen( changes.isOpen );
		},
		onStateChange: changes => {
			const { type } = changes;
			switch ( type ) {
				case useSelect.stateChangeTypes.ItemClick:
					onChange( changes.selectedItem ? changes.selectedItem : selectedItem, name );
					break;
				case useSelect.stateChangeTypes.ToggleButtonBlur:
					closeAll();
					break;
				default:
					break
			}
		},
		selectedItem,
		isOpen
	} );
	return (
		<div className="flex flex-column field mb-0 dropdown-container">
			<label className="p-mb-2 flex-item" htmlFor={ name }>{ t( `${ label }` ) }</label>
			<div { ...getToggleButtonProps(
			) } className={ selectedItem ? 'flex-item select-field flex justify-content-between' : 'flex-item select-field flex justify-content-between empty' } >
				{ selectedItem ? selectedItem.name : t( 'Choose an option' ) }
				<i className="pi pi-angle-down"></i>
			</div>

			<ul { ...getMenuProps() } className="listbox">
				{ isOpen &&
					items.map( ( item: any, index: any ) => (
						<li
							data-name={ name }
							// ishighlighted={ highlightedIndex === index }
							key={ `${ item.value }${ index }` }
							{ ...getItemProps( { item, index } ) }
							className={
								index === highlightedIndex
									? 'highlighted'
									: undefined
							}
						>
							{ item.name }
						</li>
					) ) }
			</ul>
			<div tabIndex={ 0 } />
		</div>
	);
};

export default DropdownField;
