import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Marker, useMapEvents } from 'react-leaflet'
import { Icon, LatLng } from 'leaflet';
import { useRecoilState } from 'recoil';
import { resetLocationState } from '../../atoms/damageForm';

export interface LocationMarkerProps {
	onPositionChange: ( newPosition: LatLng ) => void;
}

let persistedDamageForm = localStorage.getItem( 'damageForm' ) ? JSON.parse( localStorage.getItem( 'damageForm' ) || '' ) : {};

const LocationMarker: React.FC<LocationMarkerProps> = ( { onPositionChange } ) => {
	const [ resetLocation, setResetLocation ] = useRecoilState<boolean>( resetLocationState );

	const [ position, setPosition ] = useState<any>( persistedDamageForm.damageFormState && persistedDamageForm.damageFormState.lat !== '' ? [ persistedDamageForm.damageFormState.lat, persistedDamageForm.damageFormState.lng ] : null );
	const markerRef = useRef<any>( null )
	const [ isDragging, setIsDragging ] = useState<boolean>( false )

	const eventHandlers = useMemo(
		() => ( {
			dragend () {
				const marker = markerRef.current;
				setIsDragging( true );
				if ( marker != null ) {
					setPosition( marker.getLatLng() )
				}
			},
		} ),
		[],
	);

	// Notify when position changes
	useEffect( () => {
		if ( position ) {
			onPositionChange( position );
		}
	}, [ position, onPositionChange ] );

	const map = useMapEvents( {
		click ( e ) {
			if ( isDragging ) {
				setIsDragging( false );
				return
			}
			setPosition( e.latlng )
		},
		locationfound ( e ) {
			setPosition( e.latlng )
			map.panTo( e.latlng )
		},
	} )
	useEffect( () => {
		if ( resetLocation ) {
			setPosition( null );
			map.locate()
			setResetLocation( false );

			return function cleanup () {
				map.stopLocate();
			};
		}

	}, [ resetLocation, map, setResetLocation ] );
	useEffect( () => {
		if ( persistedDamageForm.damageFormState && persistedDamageForm.damageFormState.lat ) {
			return
		}
		map.locate()
		return function cleanup () {
			map.stopLocate();
		};
	}, [ map ] );

	return position === null ? null : (
		<Marker position={ position }
			icon={ new Icon( { iconUrl: "/arrow.svg", iconSize: [ 50, 82 ], iconAnchor: [ 25, 82 ] } ) }
			draggable
			eventHandlers={ eventHandlers }
			ref={ markerRef }>
		</Marker>
	)
}

export default LocationMarker;
