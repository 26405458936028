import React, { useCallback, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { fileToBase64String } from '../../utils/files';
import { ImageCategoryEnum, imageCategoryAtoms } from '../../atoms/imageInputs';
import './ImagePicker.scss';

export interface ImagePickerProps {
	category: ImageCategoryEnum
}

const ImagePicker: React.FC<ImagePickerProps> = ( { category } ) => {

	const [ imagesData, setImagesData ] = useRecoilState( imageCategoryAtoms[ category ] );
	const fileInputRef = useRef<HTMLInputElement>( null );

	const deleteImage = ( index: any ) => {
		let updatedImagesList = [ ...imagesData.images ]
		updatedImagesList.splice( index, 1 );
		setImagesData( {
			...imagesData,
			images: updatedImagesList
		} );
	}

	const onFileSelection = useCallback(
		async ( event: React.ChangeEvent<HTMLInputElement> ) => {
			if ( event.target.files ) {
				for ( let i = 0; i <= event.target.files.length - 1; i++ ) {
					try {
						const base64FileData: any = await fileToBase64String(
							event.target.files[ i ]
						);
						const result = `data:image;base64,${ base64FileData }`;
						setImagesData( ( imagesData ) => {
							return {
								...imagesData,
								images: [ ...imagesData.images, result ]
							}
						} );
					} catch ( e ) { }
				}
			}


		},
		[ setImagesData ]
	);

	const triggerFileInput = useCallback( (): boolean => {
		if ( fileInputRef.current ) {
			fileInputRef.current.click();
			return true;
		}
		return false;
	}, [] );

	const handleLabelClick = useCallback(
		( e: React.MouseEvent<HTMLLabelElement, MouseEvent> ) => {
			e.preventDefault();
			triggerFileInput();
		},
		[ triggerFileInput ]
	);

	return (
		<div className="image-picker-outer-container">
			<div className="image-visualization">
				<div className="icons-wrapper">
					<div className="icon-container">
						<label htmlFor="capture-fileimage" className="camera-input-label" onClick={ handleLabelClick }>

							<i className="pi pi-images" style={ { fontSize: '3rem' } } />
							<span className="icon-caption">Browse</span>
						</label>
						<input
							type="file"
							accept="image/*"
							ref={ fileInputRef }
							style={ { display: 'none' } }
							id="capture-fileimage"
							className="capture-image"
							multiple
							onChange={ onFileSelection }
						/>
					</div>
				</div>
				<div className="image-scroll-left">
					{ imagesData.images.length > 0 && (
						imagesData.images.map( ( image, index ) => (
							<div key={ index } className="img-selected">
								<img src={ image } alt="img-data" className="image" />
								<i onClick={ () => deleteImage( index ) } className="pi pi-times icon"></i>
							</div>
						) )
					) }
				</div>
			</div>
		</div>
	);
}

export default ImagePicker;